import React, { useState, useEffect } from "react";
import { useCaseStudyQuery } from "@querys/useCaseStudyQuery";
import ModuleWrapper from "../moduleWrapper";
import { Link } from "gatsby";
import { motion, AnimateSharedLayout } from "framer-motion";
import cx from "classnames";
import Plus from "@svg/plus.svg";

const variants = {
  nonActive: {
    width: "80%",
    height: "35%",
    top: "10%",
    left: "10%",
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  active: {
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
};

const variants2 = {
  nonActive: {
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
  active: {
    opacity: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const ProjectsBlock = ({
  config,
  maxItems,
  singleProjects,
  projectsByTag,
  startingItems,
}) => {
  const { nodes } = useCaseStudyQuery();
  const [filteredProjects, setFilteredProjects] = useState(nodes);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [projectsAmount, setProjectsAmount] = useState(
    maxItems || startingItems
  );

  useEffect(() => {
    let projectsDisplayed = [];

    if (projectsByTag?.title) {
      projectsDisplayed = nodes.filter(
        (node) => node.tag && node.tag.title === projectsByTag.title
      );
    }

    if (singleProjects && singleProjects.length > 0) {
      const slugFilteredProjects = nodes.filter((node) =>
        singleProjects.some(
          (project) => project?.slug?.current === node?.slug?.current
        )
      );
      projectsDisplayed =
        projectsDisplayed.length > 0
          ? projectsDisplayed.concat(slugFilteredProjects)
          : slugFilteredProjects;
    }

    // If no filtering criteria are provided, use a slice of all nodes
    if (!projectsByTag && (!singleProjects || singleProjects.length === 0)) {
      projectsDisplayed = nodes.slice(0, projectsAmount);
    }

    setFilteredProjects(projectsDisplayed);
  }, [nodes, singleProjects, projectsByTag, projectsAmount]);

  const handleMore = () => {
    setProjectsAmount((projectsAmount) => projectsAmount + 3);
  };

  return (
    <ModuleWrapper {...config} className="flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {filteredProjects?.map((node, i) => (
          <motion.div
            className="h-[700px] w-full relative flex items-end justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            key={i}
          >
            <div
              layout
              className={cx(
                "z-10  px-10  pt-16 pb-10  text-center  transition-all ease-in-out delay-100 duration-500 flex flex-col ",
                {
                  "text-white": i === activeIndex,
                  "text-dark-grey": i !== activeIndex,
                }
              )}
            >
              <AnimateSharedLayout>
                <motion.h1
                  layout="position"
                  className="text-xl pb-4 w-fit mx-auto"
                >
                  {node?.title}
                </motion.h1>

                <div
                  className={cx("text-sm  py-6", {
                    "absolute top-0 right-0 opacity-0": i === activeIndex,
                  })}
                >
                  <motion.p
                    className="line-clamp-3	"
                    variants={variants2}
                    initial="nonActive"
                    animate={i === activeIndex ? "active" : "nonActive"}
                  >
                    {node.excerpt}
                  </motion.p>
                </div>
              </AnimateSharedLayout>
              <Link
                onMouseEnter={() => setActiveIndex(i)}
                onMouseLeave={() => setActiveIndex(null)}
                to={`/${node?.slug.current}`}
                className={cx(
                  "row-start-4 tracking-[.1rem] self-center underline underline-offset-8 decoration-2 transition-all ease-in-out duration-500 inline-block relative z-10 pt-20 px-20 pb-10 -mt-20 -mx-20 -mb-10",
                  {
                    "text-white  ": i === activeIndex,
                    "text-dark-green ": i !== activeIndex,
                  }
                )}
              >
                READ MORE
              </Link>
            </div>

            <motion.img
              src={node?.featuredImage?.asset.url}
              className="absolute z-0  object-cover"
              alt=""
              variants={variants}
              initial="nonActive"
              animate={activeIndex === i ? "active" : "nonActive"}
            />
            {activeIndex === i && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                className="absolute top-0 left-0 w-full h-full bg-black"
              />
            )}
          </motion.div>
        ))}
      </div>
      {!maxItems && projectsAmount < nodes.length && (
        <motion.div
          className="mt-32 mx-auto flex cursor-pointer"
          onClick={handleMore}
          whileHover={{
            scale: 1.1,
          }}
        >
          <Plus className="w-[24px] mr-4 pt-1" />
          <p className="blockH6 text-dark-green ">See More</p>
        </motion.div>
      )}
    </ModuleWrapper>
  );
};

export default ProjectsBlock;

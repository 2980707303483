import React from "react";
import { graphql } from "gatsby";
import { useFeatureMessageQuery } from "@querys/useFeatureMessageQuery";

import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import FeatureMessage from "@components/nav/featureMessage";
import ProjectsBlock from "@components/pageModules/modules/projectsBlock";

const CaseStudyTemplate = ({ data: { page } }) => {
  const { pageModules, seo, pageColour } = page || {};
  const { nodes } = useFeatureMessageQuery();

  const bottomFeaturedMessage = nodes[0];

  return (
    <Layout pageColour={pageColour}>
      <PageMeta {...seo} />

      {pageModules && <ModuleZone {...pageModules} />}
      <div className="py-36">
        <h1 className="text-center text-dark-grey text-2xl mb-16">
          Related Projects
        </h1>
        <ProjectsBlock startingItems={3} />
      </div>

      {bottomFeaturedMessage && <FeatureMessage {...bottomFeaturedMessage} />}
    </Layout>
  );
};

export default CaseStudyTemplate;

export const pageQuery = graphql`
  query standardCaseStudyQuery($slug: String!) {
    page: sanityCaseStudy(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      pageModules: content {
        ...PageModules
      }
      title
      pageColour {
        title
        value
      }
      excerpt
      featuredImage {
        ...ImageWithPreview
      }
    }
  }
`;
